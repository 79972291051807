#navbar {
    /* background: #0288d1; */
    /* background: #0277BD; */
    background: #1e88e5;
    /* background: #1976D2; */
    /* background: #1565C0; */
    /* background: #448AFF; */
}
#navbar a:hover {
    opacity: 1;
}
#navbar a {
    opacity: 0.8;
    color: white !important;
}
#navbar a.item.active {
    opacity: 1;
}
/* .ui.sidebar.menu {
    background: #3a5e7d;
} */
/* .ui.sidebar.menu a {
    text-align: left !important;
} */

.app {
    padding: 10px;
}
.login-container,
.no-admin-access {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.no-admin-access {
    font-size: 1.7em;
    opacity: 0.8;
    text-align: center;
    line-height: 1.2em;
}
.login-container-inner {
    min-width: 330px;
}
.login-container-inner .firebaseui-list-item {
    margin: 2em !important;
}
.demo-sign-in {
    text-align: center;
    margin-top: 3em;
    padding-top: 1em;
    border-top: 1px #ccc solid;
}

.order-details {
    white-space: pre-wrap;
}

.group-label {
    font-weight: bold;
    font-size: 1.1rem;
    padding: 1.2em !important;
}

.order-popup-content {
    min-height: 50vh;
    padding: 1em;
}

.order-popup-message textarea {
    width: 100%;
    height: 100px;
    padding: 5px;
}
.order-popup-message {
    padding: 5px;
}
.order-history-message {
    margin: 0.7em 0;
}

.admin-market-form textarea {
    min-height: 200px;
}
.admin-market-form .ui.toggle.checkbox {
    margin: 0.2em 0;
}
.admin-market-form .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #1cce37 !important;
}
